import { fg } from '@atlassian/jira-feature-gating';
import { getInteractionId } from '@atlassian/react-ufo/interaction-id-context';
import { getCurrentInteractionType } from '@atlassian/react-ufo/interaction-metrics';

/**
 * Return capability header value to be passed to `x-atlassian-capability` HTTP header
 * So that backend can attribute metrics to FE experience
 *
 * https://hello.atlassian.net/wiki/spaces/PREE/pages/3968281995/Backend+metrics+-+enhanced+capability+header
 */
export function getEnhancedCapabilityHeader(capability: string | null | undefined) {
	const isEnhancedCapabilityHeaderEnabled = fg('jira_enhanced_capability_header');

	if (!isEnhancedCapabilityHeaderEnabled) {
		return capability;
	}

	if (capability == null) {
		return capability;
	}

	const defaultCapabilityHeader = `${capability}--other`;

	const interactionId = getInteractionId().current;
	if (!interactionId) {
		return defaultCapabilityHeader;
	}

	const currentInteractionType = getCurrentInteractionType(interactionId);

	if (currentInteractionType === 'page_load') {
		return `${capability}--page-load`;
	} else if (currentInteractionType === 'transition') {
		return `${capability}--transition`;
	}
	return defaultCapabilityHeader;
}
