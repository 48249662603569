import React, { useCallback, type ReactNode } from 'react';
import { ErrorFlagRenderer } from '@atlassian/jira-error-boundary-flag-renderer';
import ErrorBoundary, {
	type Props as ErrorBoundaryProps,
	type ErrorBoundaryFallbackComponent,
	type ExtraErrorAttributes,
} from '@atlassian/jira-error-boundary/src/main.tsx';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';

export interface JSErrorBoundaryProps extends ErrorBoundaryProps {
	id: string; // overriden to mandatory
	children: ReactNode; // overriden to mandatory

	fallback?: 'page' | 'flag' | 'unmount' | ErrorBoundaryFallbackComponent;
	// This one eslint-disable is better than multiple FlowFixMe(s) in all the usages where attributes is given as a function

	attributes?: AnalyticsAttributes | ((arg1: Error) => AnalyticsAttributes);
	sendToPrivacyUnsafeSplunk?: boolean;
}

const ErrorPage: ErrorBoundaryFallbackComponent = ({ error }: { error: Error }) => (
	<JSErrorPageAsync error={error} />
);
const Empty: ErrorBoundaryFallbackComponent = () => null;
const getFallback = (
	fallback: JSErrorBoundaryProps['fallback'],
	ErrorFlag: ErrorBoundaryFallbackComponent,
): ErrorBoundaryFallbackComponent | undefined => {
	switch (fallback) {
		case 'page':
			return ErrorPage;
		case 'flag':
			return ErrorFlag;
		case 'unmount':
			return Empty;
		default:
			return fallback;
	}
};

/**
 * This component acts as a safeguard for JavaScript applications by capturing errors within child components,
 * logging these errors for further analysis,
 * and substituting the errored component tree with a customizable fallback UI.
 * It enhances error handling by allowing developers
 * to specify their own error processing logic and fallback content,
 * ensuring users are presented with a graceful degradation of service in case of errors.
 *
 * @link https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 *
 * WARNING a React error boundary does not catch all errors!
 */
export const JSErrorBoundary = ({
	id,
	packageName,
	teamName,
	fallback = 'page',
	children,
	attributes,
	sendToPrivacyUnsafeSplunk,
	onError,
	...rest
}: JSErrorBoundaryProps) => {
	const ErrorFlag = useCallback(
		({ error }: Parameters<ErrorBoundaryFallbackComponent>[0]) => (
			<ErrorFlagRenderer id={id} error={error} />
		),
		[id],
	);
	const onErrorHandler = useCallback(
		(location: string, error: Error, errorAttributes: ExtraErrorAttributes) => {
			if (onError) {
				onError(location, error, errorAttributes);
			}
		},
		[onError],
	);
	return (
		<ErrorBoundary
			id={id}
			packageName={packageName}
			teamName={teamName}
			render={getFallback(fallback, ErrorFlag)}
			onError={onErrorHandler}
			{...rest}
		>
			<ReportErrors
				id={id}
				packageName={packageName}
				teamName={teamName}
				attributes={attributes}
				sendToPrivacyUnsafeSplunk={sendToPrivacyUnsafeSplunk}
			>
				{children}
			</ReportErrors>
		</ErrorBoundary>
	);
};
JSErrorBoundary.defaultProps = {
	extraEventData: {},
};
