import React, { type ReactElement } from 'react';
import { envType } from '@atlassiansox/analytics-web-client';
import { EngagementProvider as EngagementProviderWithoutConfig } from '@atlassiansox/engagekit-ts';
import { ProductIds } from '@atlassian/ipm-choreographer';
import { ff } from '@atlassian/jira-feature-flagging';
import getMeta from '@atlassian/jira-get-meta';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import {
	toEnvironment,
	type Environment,
} from '@atlassian/jira-shared-types/src/tenant-context.tsx';

type Props = {
	children: ReactElement;
	orgId?: string;
	workspaceId?: string;
};
/**
 * Conditionally renders its children or enriches them with specific configurations for engagement and analytics. This component acts differently based on the execution environment (server vs client) and the availability of user metadata, aiming to seamlessly integrate analytics functionality where applicable.
 */
const EngagementProvider = (props: Props) => {
	if (__SERVER__) {
		return props.children;
	}

	const environment = toEnvironment(getMeta('ajs-environment'));
	const getEnvironmentType = (env: Environment | null) => {
		if (env === 'prod') {
			return envType.PROD;
		}
		if (env === 'staging') {
			return envType.STAGING;
		}
		return envType.DEV;
	};

	const aaid = getMeta('ajs-atlassian-account-id');
	if (aaid === null) {
		return props.children;
	}

	const config = {
		aaid,
		cloudId: getMeta('ajs-cloud-id') || '',
		locale: getMeta('ajs-user-locale') || '',
		product: 'jira',
		stargateUrl: '/gateway/api',
		orgId: props.orgId,
	};

	const coordinationOptions = {
		envType: getEnvironmentType(environment),
		enableChoreographer: ff('post-office_enable_jira_choreographer'),
		additionalData: { sourceProductId: ProductIds.JIRA },
	};

	return (
		<EngagementProviderWithoutConfig
			{...props}
			config={config}
			analyticsClientInstance={Promise.resolve(
				getAnalyticsWebClientPromise().then((client) => client.getInstance()),
			)}
			coordinationOptions={coordinationOptions}
		/>
	);
};
export default EngagementProvider;
