import React, { useContext, useEffect } from 'react';
import noop from 'lodash/noop';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import Context, { type ExperienceContext } from '../../common/context';
import type { TrackerProps } from '../../common/types';
import TrackerBase from './view';

export const defaultProps = {
	failureEventAttributes: null,
	parentProviders: null,
	children: null,
};

export const ExperienceTracker = ({ children, ...props }: TrackerProps) => {
	const context: ExperienceContext | null = useContext(Context);
	useEffect(() => {
		if (context === null) {
			log.safeErrorWithoutCustomerData(
				'experience-tracker.missing-provider',
				'Experience tracker rendered without a provider',
			);
		}
	}, [context]);
	if (context == null || __SERVER__) {
		return typeof children === 'function' ? (
			children({
				onExperienceSuccess: noop,
				onExperienceFailure: noop,
				onExperienceAbort: noop,
			})
		) : (
			<>{children}</>
		);
	}
	return (
		<TrackerBase
			location={props.location}
			experienceContext={context}
			failureEventAttributes={props.failureEventAttributes}
			parentProvidersWhitelist={props.parentProviders}
		>
			{children}
		</TrackerBase>
	);
};
ExperienceTracker.defaultProps = defaultProps;
