import { Component, type ComponentType, type ReactNode, type ErrorInfo } from 'react';
import {
	withAnalyticsEvents,
	type UIAnalyticsEvent,
	type WithAnalyticsEventsProps,
} from '@atlaskit/analytics-next';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import fireErrorAnalytics from './fire-error-analytics';

interface Props extends WithAnalyticsEventsProps {
	// We are overloading `attributes` with a callback. For backwards compatibility we don't want to change the prop name

	attributes?: AnalyticsAttributes | ((arg1: Error) => AnalyticsAttributes);
	children: ReactNode;
	// this prop is coming from Atlaskit, can't rename it
	createAnalyticsEvent: NonNullable<WithAnalyticsEventsProps['createAnalyticsEvent']>;
	id: string;
	packageName?: string;
	teamName?: string;
	sendToPrivacyUnsafeSplunk?: boolean;
}

type State = {
	analyticsEvent: UIAnalyticsEvent | null;
};

class CatchAndReportErrors extends Component<Props, State> {
	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		const {
			packageName,
			teamName,
			id,
			attributes,
			createAnalyticsEvent,
			sendToPrivacyUnsafeSplunk,
		} = this.props;
		const analyticsEvent = createAnalyticsEvent({ action: 'failed' });

		fireErrorAnalytics({
			event: analyticsEvent,
			errorInfo,
			error,
			meta: {
				id,
				packageName,
				teamName,
			},
			attributes: typeof attributes === 'function' ? attributes(error) : attributes,
			sendToPrivacyUnsafeSplunk,
		});

		throw error;
	}

	render() {
		return this.props.children;
	}
}

type ReportErrorsType = ComponentType<
	Flow.Diff<
		Props,
		{
			createAnalyticsEvent: Props['createAnalyticsEvent'];
		}
	>
>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default withAnalyticsEvents()(CatchAndReportErrors) as ReportErrorsType;
