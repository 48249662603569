import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import { getSSRMark } from '../../ssr/ssr-mark';
import type { CHRReporterSummary } from './types';
import { CHRReporter } from './utils';
/**
 * Collects and summarizes web resource loading performance data during the initial page load.
 * Returns a summary object or an empty object in case of no data or errors.
 */
export const getCHRInfo = ({ start }: { start: number }): CHRReporterSummary | {} => {
	// initial load only
	if (start !== 0) {
		return {};
	}
	const ssrMark = getSSRMark();
	const reporter = new CHRReporter(ssrMark);
	try {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		for (const entry of performance.getEntriesByType('resource') as PerformanceResourceTiming[]) {
			reporter.add(entry);
		}
		const chr = reporter.get();
		return chr === null
			? {}
			: {
					chr,
				};
	} catch (e: unknown) {
		return {};
	}
};
