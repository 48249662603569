import parse from 'url-parse';
import { getUserTiming } from 'uxm';
import { JS_EVAL_START, JS_EVAL_STOP } from '@atlassian/jira-common-constants/src/analytics-marks';
import { getMemoryUsageReport } from '@atlassian/jira-memory-metrics/src/services/measure-memory/index.tsx';
import { getServerDurationMetrics } from '../../collector/server-duration';
import { getPageSizeInfo } from '../page-size';
import { getCacheHitRatio } from './cache-hit-ratio';
import { getCHRInfo } from './chr';
import { getRouterResourceMetrics } from './router-resource-metrics';
import { getWRMStats } from './wrm-stats';

const endpointsWithOperationNameRegex = new RegExp(
	'/rest/graphql/1/|/rest/gira/1/|/gateway/api/graphql|/jsw2/graphql',
);
/**
 * Prepares a collection of functions dedicated to gathering key performance metrics during page load. This includes custom data related to server duration, router resources, cache statistics, and page size, among others. Designed to enhance performance monitoring across web applications.
 */
export const getPageLoadPlugins = () => {
	const pageLoadCustomData = [];
	pageLoadCustomData.push(
		(data: {
			start?: number;
			result?: {
				startTime: number;
			};
		}) => {
			// initial load only
			const startTime = data?.start || data?.result?.startTime || 0;
			if (startTime !== 0) {
				return {};
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return Object.entries(getServerDurationMetrics()).reduce<Record<string, any>>(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(acc, [key, value]: [any, any]) => {
					acc[key] = value;
					return acc;
				},
				{},
			);
		},
	);
	pageLoadCustomData.push(getRouterResourceMetrics);
	pageLoadCustomData.push(getWRMStats);
	pageLoadCustomData.push(getCacheHitRatio);
	pageLoadCustomData.push(getCHRInfo);
	pageLoadCustomData.push(getPageSizeInfo);
	return pageLoadCustomData;
};
export const getMemoryMetrics = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const customData: Record<string, any> = {};
	const memoryUsageReport = getMemoryUsageReport();
	if (memoryUsageReport) {
		customData.memory = memoryUsageReport;
	}
	return customData;
};
/**
 * Gathers, filters, and adjusts the names of performance marks from the browser's performance timeline to create a standardized dataset. This facilitates more effective performance analysis by excluding irrelevant marks and ensuring the uniqueness of each entry, making it invaluable for optimizing web application performance.
 */
export const getMarks = (data: { start: number }) => {
	const userTiming = getUserTiming();
	if (userTiming === null) return null;
	const sanitizeName = (name: string) => name.replace(/[\W_]+/g, '_');
	// @ts-expect-error - TS7031 - Binding element 'baseName' implicitly has an 'any' type. | TS7031 - Binding element 'collection' implicitly has an 'any' type.
	const getNextAvailableName = ({ baseName, collection }) => {
		let candidateName = baseName;
		// If <name> is taken, we'll try <name>_2 and increment from there
		let counter = 2;
		let validName;
		while (!validName) {
			if (candidateName in collection) {
				candidateName = `${baseName}_${counter}`;
				counter += 1;
			} else {
				validName = candidateName;
			}
		}
		return validName;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const marks: Record<string, any> = {};
	// Excludes mark starting with '_'. This allows marks to opt-out from the report.
	userTiming
		.filter(
			// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
			(item) =>
				item.type === 'mark' &&
				!item.name.startsWith('_') &&
				!item.name.endsWith(JS_EVAL_START) &&
				!item.name.endsWith(JS_EVAL_STOP) &&
				item.startTime >= data.start,
		)
		// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
		.forEach((item) => {
			const name = getNextAvailableName({
				baseName: `mark__${sanitizeName(item.name)}`,
				collection: marks,
			});
			marks[name] = Math.round(item.startTime - data.start);
		});
	return marks;
};
export const sanitiseEndpoints = (url: string) => {
	const parsedUrl = parse(url, true);
	let fileName = parsedUrl.pathname
		.replace(/\/(\w+)-(\d+)\//i, '/<issue-key>/')
		.replace(
			/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/i,
			'<uuid>',
		)
		.replace(/\/pq\/[a-fA-F0-9]+/i, '/pq/<id>') // persisted queries
		.replace(/\/\d+$/i, '/<id>');

	/**
	 * For these endpoints we make a special exception to add the query parameters so that we can distinguish calls using different queries
	 */
	if (endpointsWithOperationNameRegex.test(fileName)) {
		const { operation } = parsedUrl.query;
		if (operation) {
			fileName += `?operation=${operation}`;
		}
	}
	return fileName;
};
