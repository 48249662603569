import React, { useEffect, type ReactNode } from 'react';
import { JiraStructuredAnalyticsListener as StructuredAnalyticsListener } from '@atlassian/jira-analytics-providers/src/ui/structured-analytics-listener/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import { measureInitialPageLoadTiming } from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import initAnalytics from './common/utils/init-analytics';

type Props = {
	children: ReactNode;
};
/**
 * Enhances child components by providing analytics context, essential for enabling
 * comprehensive analytics across the application. It selectively initializes
 * analytics tools based on organizational and tenant contexts, ensuring
 * relevant data capture. In server environments, it renders children
 * directly for efficiency, while in client environments, it enriches
 * children with structured analytics capabilities for detailed
 * monitoring and insights.
 */
export const AnalyticsProviders = ({ children }: Props) => {
	const tenantContext = useTenantContext();
	const { data: orgId } = useOrgId();
	useEffect(() => {
		if (__SERVER__) {
			return;
		}
		try {
			const success = measureInitialPageLoadTiming(
				`analytics-providers/init-analytics${getAnalyticsWebClient() ? '' : ':initial'}`,
				() =>
					initAnalytics({
						...tenantContext,
						orgId,
					}),
			);
			if (success === undefined) {
				throw new Error(
					'observability.analytics-providers-error: @atlassiansox/analytics-web-client AnalyticsClient in src/common/analytics-web-react/web-client/index.js failed to initialise successfully',
				);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			log.safeErrorWithoutCustomerData('observability.analytics-providers.analytics', e);
		}
	}, [orgId, tenantContext]);
	if (__SERVER__) {
		return <>{children}</>;
	}
	return (
		<StructuredAnalyticsListener>
			<ContextualAnalyticsData
				attributes={{
					isSPA: __SPA__,
					variant: window.BUILD_VARIANT,
				}}
			>
				{children}
			</ContextualAnalyticsData>
		</StructuredAnalyticsListener>
	);
};
export default AnalyticsProviders;
