import React, { type ReactNode } from 'react';
import FabricAnalyticsListeners, { FabricChannel } from '@atlaskit/analytics-listeners';
import { type UIAnalyticsEvent, AnalyticsListener } from '@atlaskit/analytics-next';
import getEventLegacy from '@atlassian/jira-analytics-web-react/src/utils/get-event-for-web-client.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { getEvent } from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsEvent, UnknownEvent } from '@atlassian/jira-product-analytics-web-client';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { addSearchSessionAttributes } from '../../common/utils/add-search-session-attributes';

type Props = {
	children: ReactNode;
	onEvent: (event: UIAnalyticsEvent, channel?: string) => void;
	onEventV3: (event: UIAnalyticsEvent) => void;
};

const EXCLUDED_CHANNELS = [FabricChannel.atlaskit];

const addSearchPayload = (event: UIAnalyticsEvent, isProductAnalyticsV3 = false) => {
	try {
		const searchPayload = addSearchSessionAttributes();

		if (Object.keys(searchPayload).length > 0) {
			if (isProductAnalyticsV3) {
				// eslint-disable-next-line no-param-reassign
				event.payload.attributes = {
					...event.payload.attributes,
					...searchPayload,
				};
			} else {
				// eslint-disable-next-line no-param-reassign
				event.payload = {
					...event.payload,
					...searchPayload,
				};
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		log.safeErrorWithoutCustomerData(
			'observability.analytics-providers.addSearchPayload',
			'Failed to dynamically add search payload',
			e,
		);
	}

	return event;
};

const getAnalyticsWebClientInstance = () =>
	getAnalyticsWebClientPromise().then((client) => client.getInstance());

const sendAnalyticsEvent = (event: AnalyticsEvent | UnknownEvent) => {
	// Preemptively create an error so we can find who sends invalid events in Sentry
	const callError = new Error(`This ${event.type} event is not valid`);
	getAnalyticsWebClientPromise().then((client) => {
		try {
			client.sendEvent(event);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			err.cause = callError;
			throw err;
		}
	});
};

const onStructuredAnalyticsEvent = (event: UIAnalyticsEvent) => {
	const enrichedEvent = addSearchPayload(event);
	sendAnalyticsEvent(getEventLegacy(enrichedEvent));
};

const onProductAnalyticsV3 = (event: UIAnalyticsEvent) => {
	const enrichedEvent = addSearchPayload(event, true);
	sendAnalyticsEvent(getEvent(enrichedEvent));
};

const JiraAnalyticsListener = ({ onEvent, onEventV3, children }: Props) => (
	<AnalyticsListener onEvent={onEvent}>
		<AnalyticsListener onEvent={onEventV3} channel="productAnalytics">
			{children}
		</AnalyticsListener>
	</AnalyticsListener>
);

export const JiraStructuredAnalyticsListener = ({ children }: { children: ReactNode }) => {
	if (__SERVER__) {
		return null;
	}

	return (
		<FabricAnalyticsListeners
			// @ts-expect-error - Type 'GasPurePayload' is not assignable to type 'UITrackAndOperationalEventPayload'.
			client={getAnalyticsWebClientInstance()}
			excludedChannels={EXCLUDED_CHANNELS}
		>
			<JiraAnalyticsListener onEvent={onStructuredAnalyticsEvent} onEventV3={onProductAnalyticsV3}>
				{children}
			</JiraAnalyticsListener>
		</FabricAnalyticsListeners>
	);
};
