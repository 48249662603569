import { initAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import getApplicationSolutions from '../get-application-solutions';
import getApplicationSubProduct from '../get-application-sub-product';
import getStagingEnvironment from '../get-staging-environment';
import { logEmau } from './log-emau';

export interface InitAnalyticsData extends TenantContext {
	workspaceId?: string;
	orgId?: string;
}
/**
 * Initializes the analytics web client with configuration tailored to the user and product environment.
 * It dynamically adjusts settings based on user and organizational details to enhance product analytics.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	cloudId,
	cloudName,
	atlassianAccountId,
	environment,
	locale,
	orgId,
	activationId: workspaceId,
}: InitAnalyticsData) =>
	initAnalyticsWebClient({
		tenantId: cloudId,
		...(atlassianAccountId
			? {
					userId: atlassianAccountId,
				}
			: undefined),
		...(orgId && {
			orgId,
		}),
		...(workspaceId && {
			workspaceId,
		}),
		product: 'jira',
		environment:
			environment === 'prod'
				? 'prod'
				: getStagingEnvironment({
						cloudId,
						cloudName,
						environment,
					}),
		callback: logEmau,
		subProduct: getApplicationSubProduct,
		solutions: getApplicationSolutions(),
		locale,
	});
